import React from "react";

import {
  Row,
  Col,
  Button,
  Tag,
  Typography,
  List,
  Divider,
  Space,
  Popover,
} from "antd";
import { useEffect, useRef, useState } from "react";
import { MoreOutlined } from "@ant-design/icons";
import {
  useMatch,
} from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "react-query";

import createPersistedState from "use-persisted-state";
import CustomHeader from "../../components/header";
import {
  PubmedDataItems,
} from "../../services/dataService";
import FilterView from "./filterView";
import * as _ from "lodash";
import TableView from "./tableView";
import ConfigEditView from "./configEditView";
import PubDetailView from "./detailView";
import ScoringView from "./scoring";

const labelAppState = createPersistedState("labelAppState");
const globalAppState = createPersistedState("globalAppState");

function PubmedDataHome(props) {
  const match = useMatch("/dataset/:id");

  const queryClient = useQueryClient();

  const [state, setState] = useState({
    tableView: false,
    loading: true,
    editConfig: false,
    page: 1,
    search: [],
  });
  const ref = useRef(null);

  useEffect(() => {
    // ref.current?.scrollIntoView({
    //   block: "start",
    //   inline: "nearest",
    //   behavior: "smooth",
    // });
  }, [state.selected]);

  // const labelTaskDetail = useQuery(
  //   ["dataset_detail", match.params.id],
  //   () => PubmedData.get(match.params.id),
  //   { enabled: match.params.id != undefined }
  // );

  const datasetItems = useQuery(
    ["dataset_items", state.searchNFilter, state.page],
    () => {
      // if (state.search == undefined || state.search.length == 0) {
      //   return PubmedDataItems.getAll({
      //     page: state.page,
      //     data_id: match.params.id,
      //   });
      // } else {
      return PubmedDataItems.search({
        page: state.page,
        search: state.searchNFilter?.search,
        pmids: state.searchNFilter?.pmids,
        filters: state.searchNFilter?.filters
          ? JSON.stringify(state.searchNFilter?.filters)
          : undefined,
        // data_id: match.params.id,
      });
      // }
    },
    {
      staleTime: Infinity,
    }
  );

  const exportResults = useMutation(() => {
    return PubmedDataItems.exportToExcel({
      search: state.searchNFilter?.search,
      filters: state.searchNFilter?.filters
        ? JSON.stringify(state.searchNFilter?.filters)
        : undefined,
    });
  });


  const summaryMutation = useMutation(async () => {
    const searchItems = datasetItems;

    let pubmedIds = searchItems.data?.results.map((item) => item.pubmed_id);
    return PubmedDataItems.summarize({
      pubmed_ids: pubmedIds,
      query: state.searchNFilter?.search,
    });
  });

  const searchItems = datasetItems;

  const nextLabelItem = useMutation(() =>
    PubmedDataItems.get_next(
      state.selected.id,
      {
        acquire_lock: true,
        is_labelled: false,
        task_id: state.selected.task_id,
      },
      {
        onSuccess: (data) => {
          props.onNext(data);
        },
      }
    )
  );

  const loadAndMoveToNext = async () => {
    nextLabelItem.mutateAsync().then((data) => {
      setState((prev) => ({ ...prev, selected: data }));
    });

    //  props.onNext(nextLabelItem.data);
  };

  const viewOptions = [
    { label: "List", value: "listView" },
    { label: "Single", value: "singleView" },
  ];

  const debounceUpdate = _.debounce((value) => {
    debugger
    setState((prev) => ({ ...prev, searchNFilter: value }));
  }, 500);

  console.log("state.options ", state);
  return (
    <div>
      <CustomHeader />
      <ScoringView
        filters={state.searchNFilter?.filters}
        visible={state.scoring}
        onCancel={() => {
          setState((prev) => ({ ...prev, scoring: false }));
        }}
      />
      <ConfigEditView
        open={state.editConfig}
        onClose={() => {
          setState((prev) => ({ ...prev, editConfig: false }));
        }}
      />
      <div style={{ maxWidth: "1200px", margin: "0px auto" }}>
        <Row>
          <Row style={{ width: "100%", margin: "0.5rem 0rem" }} align="middle">
            <Typography.Title
              level={4}
              style={{
                color: "#1677ff",
              }}
            >
              Pubmed Data
            </Typography.Title>
            <div style={{ marginLeft: "auto" }}>
              <Popover
                trigger="click"
                content={
                  <Space
                    direction="vertical"
                    split={
                      <Divider
                        style={{
                          margin: 0,
                        }}
                      />
                    }
                  >
                    <Button
                      style={{
                        width: "100%",
                      }}
                      type="link"
                      loading={exportResults.isLoading}
                      onClick={() => {
                        exportResults.mutate();
                      }}
                    >
                      Export
                    </Button>
                    <Button
                      style={{
                        width: "100%",
                      }}
                      type="link"
                      onClick={() => {
                        setState((prev) => ({ ...prev, editConfig: true }));
                      }}
                    >
                      Config
                    </Button>
                    <Button
                      style={{
                        width: "100%",
                      }}
                      type="link"
                      onClick={() => {
                        setState((prev) => ({ ...prev, scoring: true }));
                      }}
                    >
                      Scoring
                    </Button>
                    <Button
                      style={{
                        width: "100%",
                      }}
                      type="link"
                      onClick={summaryMutation.mutate}
                      loading={summaryMutation.isLoading}
                    >
                      Generate Summary
                    </Button>
                  </Space>
                }
              >
                <Button type="link">
                  <MoreOutlined />
                </Button>
              </Popover>
            </div>
          </Row>

          <FilterView
            onChange={debounceUpdate}
            onDisplayChange={(value) => {
              setState((prev) => ({ ...prev, tableView: value == "table" }));
            }}
          />
          <Row style={{ width: "100%",                 marginTop: "0.5rem",
 }}>
            <br />
            <Tag
              color="grey"
              style={{
              }}
            >
              {searchItems.data?.total?.value} Results
            </Tag>

            <div>
              {summaryMutation.data && (
                <div>
                  <Typography.Title level={4}>Summary</Typography.Title>
                  <Typography.Text>
                    {summaryMutation.data?.summary}
                  </Typography.Text>
                </div>
              )}
            </div>
          </Row>
          {state.tableView ? (
            <Row style={{ width: "100%" }}>
              <TableView
                data={searchItems.data?.results}
                onPageChange={(page, pageSize) => {
                  setState((prevState) => ({
                    ...prevState,
                    page: page,
                    size: pageSize,
                  }));
                }}
                total={searchItems.data?.total?.value}
              />
            </Row>
          ) : (
            <Row style={{ width: "100%" }}>
              <Col
                span={8}
                style={{
                  width: "100%",
                  height: "90vh",
                  overflowY: "scroll",
                  // padding: "0px 20px",
                }}
              >
                <List
                  pagination={{
                    showSizeChanger: false,
                    onChange: (page, pageSize) => {
                      setState((prevState) => ({
                        ...prevState,
                        page: page,
                        size: pageSize,
                      }));
                    },
                    total: searchItems.data ? searchItems.data.total.value : 0,
                    current: state.page,
                    pageSize: state.size || 10,
                  }}
                  grid={{
                    gutter: 2,
                    xs: 1,
                    sm: 1,
                    md: 1,
                    lg: 1,
                    xl: 1,
                    xxl: 1,
                  }}
                  dataSource={searchItems.data ? searchItems.data.results : []}
                  // dataSource={labelItems.data}
                  loading={searchItems.isLoading}
                  renderItem={(item, idx) => (
                    <List.Item key={idx}>
                      {/* <div key={item?.pubmed_id}>
                        <Card
                          style={{ margin: "1rem 0rem" }}
                          onClick={() =>
                            setState((prev) => ({
                              ...prev,
                              selected: item,
                              selectedIdx: idx,
                            }))
                          }
                        >
                          <Tag
                            color="geekblue"
                            onClick={() =>
                              window.open(
                                `https://pubmed.ncbi.nlm.nih.gov/${item["pubmed_id"]}/`,
                                "_blank"
                              )
                            }
                          >
                            PMID: {item.pubmed_id}
                          </Tag>
                          <Tag
                            color="green"
                            
                          >
                            {item.drug}
                          </Tag>
                          <br />{" "}
                          <div style={{ marginTop: "0.5rem" }}>
                            {item["title"]}
                          </div>
                        </Card>
                      </div> */}
                    </List.Item>
                  )}
                />
              </Col>
              <Col
                span={16}
                //Set scrollbar width to 0.125 rem
                style={{ width: "100%", height: "90vh", overflowY: "scroll" }}
              >
                {state.selected && (
                  <div style={{ margin: "1.5rem 1.5rem" }}>
                    <div ref={ref}></div>
                    <PubDetailView selected={state.selected} />
                  </div>
                )}
              </Col>
            </Row>
          )}
        </Row>
      </div>
      {/* </header> */}
    </div>
  );
}

export default PubmedDataHome;
