import {
  AutoComplete,
  Button,
  Cascader,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Radio,
  Select,
  Space,
  message,
  Divider,
  Typography,
  Popover,
  Tabs,
  Modal,
} from "antd";
import React, { useState, useEffect } from "react";
import {
  MinusCircleOutlined,
  PlusOutlined,
  DeleteOutlined,
  CheckOutlined,
  CloseOutlined,
  CommentOutlined,
  BookOutlined,
  LinkOutlined,
  CalendarOutlined,
  UserOutlined,
} from "@ant-design/icons";
import remarkGfm from "remark-gfm";
import ReactMarkdown from "react-markdown";
import createPersistedState from "use-persisted-state";

import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  BookmarkService,
  PubmedConfig,
  PubmedDataItems,
} from "../../services/dataService";
import ReactJson from "react-json-view";
import { useAuth } from "../../authContext";
import UploadPDF from "./importPDF";
import PDFDocView from "./DocView";
import CommentSection from "./comments";
import ValidationView from "./validationView";
import PipelineDataView from "./pipelineDataView";
import OpenAIDataView from "./openaiDataView";


const RenderValue = ({ fieldPath, feedback, value, onFeedback }) => {
  if (feedback) {
    // console.log("feedback->", feedback, fieldPath);
  }
  const [comment, setComment] = useState(feedback?.comment);
  const handleFeedback = (value) => {
    let val = value == "correct" ? { is_correct: true } : { is_correct: false };
    onFeedback(fieldPath, { ...feedback, ...val });
  };

  const handleComment = (value) => {
    onFeedback(fieldPath, { ...feedback, comment: value });
  };

  const labelContent = () => {
    return (
      <div style={{ minWidth: "300px", minHeight: "100px" }}>
        <Space direction="vertical" style={{ width: "100%" }} align="left">
          <Button type="link" onClick={() => handleFeedback("correct")}>
            {" "}
            <CheckOutlined /> Correct
          </Button>
          <Button
            type="link"
            danger
            onClick={() => handleFeedback("incorrect")}
          >
            <CloseOutlined /> Incorrect
          </Button>
          <Input.TextArea
            placeholder="Add comment"
            autoSize={{ minRows: 2, maxRows: 6 }}
            style={{ width: "100%" }}
            onChange={(e) => {
              setComment(e.target.value);
            }}
            value={comment}
          />
          <Button
            type="link"
            onClick={() => {
              handleComment(comment);
            }}
          >
            <CommentOutlined />
            Add Comment
          </Button>
        </Space>
      </div>
    );
  };
  if (Array.isArray(value)) {
    return (
      <>
        {value.map((item, index) => {
          return (
            <RenderValue
              value={item}
              fieldPath={[...fieldPath, index]}
              onFeedback={onFeedback}
              feedback={feedback[index] || {}}
            />
          );
        })}
      </>
    );
  } else if (typeof value == "object") {
    return <ReactJson src={value} />;
  } else {
    return (
      <Row
        style={{
          width: "100%",
          marginLeft: "1rem",
        }}
      >
        <Popover content={labelContent}>
          <Typography.Text>{value}</Typography.Text>{" "}
          {feedback.is_correct == true ? (
            <CheckOutlined style={{ color: "mediumseagreen" }} />
          ) : null}
          {feedback.is_correct == false ? (
            <CloseOutlined style={{ color: "red" }} />
          ) : null}
          {feedback.comment ? (
            <CommentOutlined style={{ color: "dodgerblue" }} />
          ) : null}
        </Popover>
      </Row>
    );
  }
};

const NestedField = ({
  fieldPath,
  field,
  feedback,
  mapping,
  value,
  onFeedback,
}) => {
  let properties = mapping.properties;

  //   const onChangeNested = (key, modValue) => {
  //     onChange({
  //       ...value,
  //       properties: { ...properties, [key]: { ...modValue } },
  //     });
  //   };

  let isArray = Array.isArray(value);
  let values = value;

  //   console.log("Feedback", feedback, fieldPath, field);
  return (
    <>
      {isArray && properties ? (
        <>
          <>
            <Row align="bottom" style={{ padding: "0.5rem" }}>
              <Col span={8}>
                <Typography.Text level={5} type="secondary">
                  {mapping.label || field}
                </Typography.Text>
              </Col>
              {values.map((value, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      padding: "1rem",
                      margin: "0.5rem",
                      border: "1px solid #80808066",
                      borderRadius: "10px",
                    }}
                  >
                    <Typography.Text type="secondary">
                      {mapping.label || field} #{index + 1}
                    </Typography.Text>
                    <div style={{ marginLeft: "1rem" }}>
                      {Object.keys(properties).map((key) => {
                        return (
                          <NestedField
                            key={key}
                            field={key}
                            feedback={
                              feedback[index] ? feedback[index][key] || {} : {}
                            }
                            fieldPath={[...fieldPath, field, index]}
                            mapping={properties[key]}
                            value={value[key]}
                            // onChange={(value) => onChangeNested(key, value)}
                            onFeedback={onFeedback}
                          ></NestedField>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </Row>
          </>
        </>
      ) : (
        <>
          {properties ? (
            <>
              <Row style={{ padding: "0.5rem" }} align="bottom">
                <Col span={8}>
                  <Typography.Title
                    level={5}
                    style={{
                      margin: "0px",
                    }}
                  >
                    {mapping.label || field}
                  </Typography.Title>
                </Col>
                <Col span={16}></Col>
              </Row>
              <div style={{ marginLeft: "1rem" }}>
                {Object.keys(properties).map((key) => {
                  return (
                    <NestedField
                      key={key}
                      field={key}
                      feedback={feedback[key] || {}}
                      fieldPath={[...fieldPath, field]}
                      mapping={properties[key]}
                      value={value[key]}
                      //   onChange={(value) => onChangeNested(key, value)}
                      onFeedback={onFeedback}
                    ></NestedField>
                  );
                })}
              </div>
            </>
          ) : null}
          {!properties ? (
            <Row
              style={{ padding: "0.5rem", borderBottom: "1px solid #80808066" }}
            >
              <Col span={8}>
                <Typography.Text type="secondary">
                  {mapping.label || field}
                </Typography.Text>
              </Col>
              <Col span={16}>
                {/* <Typography.Text>{value}</Typography.Text>
                 */}
                <RenderValue
                  fieldPath={[...fieldPath, field]}
                  feedback={feedback || {}}
                  onFeedback={onFeedback}
                  value={value}
                />
              </Col>
            </Row>
          ) : null}
        </>
      )}
    </>
  );
};

const DetailView = (props) => {
  //   const [feedback, setFeedback] = useState({});
  const config = useQuery(["config"], () => PubmedConfig.getConfig(), {
    onSuccess: (data) => {
      //   setState({
      //     ...state,
      //     mappings:
      //       data?.["test-pubmed-data"]?.mappings ||
      //       data?.["pubmed-data"]?.mappings,
      //   });
    },
    staleTime: Infinity,
  });

  let mappings =
    config.data?.["test-pubmed-data"]?.mappings ||
    config.data?.["pubmed-data"]?.mappings;
  let relMappings = mappings?.properties[[props.dataKey]];
  //   console.log("properties", properties);

  let addFeedbackRecursively = (feedback, fieldPath, value) => {
    // Check if key is for array by checking if key is int
    let key = fieldPath[0];
    let isKeyForArr = !isNaN(key);

    let newFeedback;
    // if key is for array, then we need to add a new objects to the current index
    if (isKeyForArr) {
      if (!Array.isArray(feedback)) {
        feedback = [];
      }
      let index = key - 1;
      // Check if array has enough elements to add new object
      let numElementsToAdd = index - feedback.length + 1;
      if (numElementsToAdd > 0) {
        for (let j = 0; j < numElementsToAdd; j++) {
          feedback.push({});
        }
      }

      newFeedback = feedback;
      newFeedback[key] = { ...newFeedback[key] };
    } else {
      newFeedback = { ...feedback };
    }

    if (fieldPath.length > 1) {
      newFeedback[fieldPath[0]] = addFeedbackRecursively(
        feedback[fieldPath[0]] || {},
        fieldPath.slice(1),
        value
      );
    } else {
      newFeedback[fieldPath[0]] = value;
    }
    return newFeedback;
  };

  let addFeedback = (fieldPath, value) => {
    let existingFeedback = { ...props.feedback };
    let newFeedback = addFeedbackRecursively(
      existingFeedback,
      fieldPath,
      value
    );
    // debugger;
    props.onUpdateFeedback(newFeedback);
  };

  return (
    <div>
      {relMappings ? (
        <>
          <NestedField
            field={props.dataKey}
            fieldPath={[]}
            mapping={relMappings}
            value={props.data}
            feedback={props.feedback[props.dataKey] || {}}
            onFeedback={(fieldPath, value) => {
              addFeedback(fieldPath, value);
            }}
          ></NestedField>
        </>
      ) : null}
    </div>
  );
};

const pubmedDetailViewTab = createPersistedState("pubmedDetailViewTab");

const PubDetailViewV2 = ({ itemId, pubmedId, selected }) => {
  const auth = useAuth();

  const [feedback, setFeedback] = useState({});
  const [newBookmarkName, setNewBookmarkName] = useState("");
  const [pdfUploadFlag, setPdfUploadFlag] = useState(false);
  const queryClient = useQueryClient();
  const [tab, setTab] = pubmedDetailViewTab("summary");

  const [pdfFlag, setPdfFlag] = useState(false);
  const updateFeedback = useMutation(
    (feedback) => PubmedDataItems.addFeedback(selected?.id, feedback),
    {
      onSuccess: (data) => {
        message.success("Feedback updated successfully");
      },
      onError: (error) => {
        message.error("Error updating feedback");
      },
    }
  );
  const pubmedItem = useQuery(
    ["pubmedItem", selected?.id],
    () => PubmedDataItems.get(selected?.id),
    {
      onSuccess: (data) => {
        setFeedback(data?.feedback || {});
      },
      staleTime: Infinity,
      enabled: selected?.id ? true : false,
    }
  );
  const handleAddFeedback = (value) => {
    let existingFeedback = { ...feedback, ...value };
    setFeedback(existingFeedback);
    updateFeedback.mutate(existingFeedback);
  };

  
  const bookmarkList = useQuery(["bookmarks"], () => BookmarkService.getAll(), {
    staleTime: Infinity,
  });

  const bookmarkItem = useMutation(
    (bookmarkId) =>
      BookmarkService.addItem({
        bookmark_id: bookmarkId,
        // pubmed_id: selected?.pubmed_id,
        item_id: selected?.id,
      }),
    {
      onSuccess: (data) => {
        message.success("Item added to bookmark");
        queryClient.invalidateQueries(["bookmarkItems"]);
        queryClient.invalidateQueries(["checkBookmark"]);
        
      },
      onError: (error) => {
        message.error("Error adding item to bookmark");
      },
    }
  );
  const createNewBookmark = useMutation(
    (name) => BookmarkService.create({ owner_id: auth.user.id, name: name }),
    {
      onSuccess: (data) => {
        message.success("Bookmark created successfully");
        // bookmarkList.refetch();
        queryClient.invalidateQueries(["bookmarks"]);
      },
      onError: (error) => {
        message.error("Error creating bookmark");
      },
    }
  );

  const checkBookmark = useQuery(
    ["checkBookmark", selected?.id],
    () => BookmarkService.checkItem(selected?.id),
    {
      enabled: selected?.id ? true : false,
    }
  );

  const bookmarkMap = {};
  checkBookmark.data?.results?.map((bookmark) => {
    bookmarkMap[bookmark.id] = bookmark;
  });

    console.log("selected", selected, pubmedItem.data)
  return (
    <>
      {selected?.id && pubmedItem.data?.has_pdf && (
        <PDFDocView
          docId={selected?.pubmed_id}
          pmid={selected?.pubmed_id}
          visible={pdfFlag}
          onClose={() => {
            setPdfFlag(false);
          }}
        />
      )}
      {selected ? (
        <>
          <UploadPDF
            itemId={selected?.id}
            visible={pdfUploadFlag}
            onCancel={() => setPdfUploadFlag(false)}
            onConfirm={() => {
              setPdfUploadFlag(false);
              queryClient.invalidateQueries(["pubmedItem", selected?.id]);
            }}
          />
          <Typography.Title
            level={3}
            style={{
              marginBottom: "0.125rem",
            }}
          >
            {selected.title}
          </Typography.Title>
          <Row>
            <Typography.Text type="secondary">
              <CalendarOutlined /> {selected?.other_details?.publication_date}
            </Typography.Text>
            <Typography.Text type="secondary">
              <UserOutlined
                style={{
                  marginLeft: "1rem",
                }}
              />{" "}
              {selected.authors?.join(", ")}
            </Typography.Text>
          </Row>

          <Space wrap split={<Divider />}>
            <Button
              size="small"
              className="option-button"
              type="text"
              onClick={() => {
                message.success("PMID Copied!");
                navigator.clipboard.writeText(selected?.pubmed_id);
              }}
            >
              <i class="fa-solid fa-copy"></i>
              PMID: {selected?.pubmed_id}
            </Button>
            <Button
              size="small"
              className="option-button"
              type="text"
              onClick={() =>
                window.open(
                  `https://pubmed.ncbi.nlm.nih.gov/${selected?.pubmed_id}/`,
                  "_blank"
                )
              }
            >
              <i class="fa-solid fa-link"></i>
              Pubmed
            </Button>

            <Button
              size="small"
              className="option-button"
              type="text"
              onClick={() =>
                window.open(selected.other_details?.full_text_url, "_blank")
              }
              disabled={!selected?.other_details?.full_text_url}
            >
              <i class="fa-solid fa-file-lines"></i> Full text
            </Button>
            <Button
              size="small"
              className="option-button"
              type="text"
              disabled={!selected?.has_pdf && !pubmedItem.data?.has_pdf}
              onClick={() => setPdfFlag(true)}
            >
              <i class="fa-solid fa-file-pdf"></i>
              View PDF
            </Button>
            <Button
              size="small"
              className="option-button"
              type="text"
              onClick={() => setPdfUploadFlag(true)}
            >
              <i class="fa-solid fa-file-arrow-up"></i>
              Upload PDF
            </Button>
            {/* <Button size="small" className="option-button" type="text">
              <i class="fa-solid fa-arrow-up-right-from-square"></i>
              Open
            </Button> */}
            <Popover
              trigger="click"
              content={
                <div style={{ width: "250px" }}>
                  <Space
                    direction="vertical"
                    split={<Divider style={{ margin: "0px" }} />}
                  >
                    {bookmarkList.data?.results?.map((bookmark) => (
                      <Button
                        type="link"
                        onClick={() => {
                          bookmarkItem.mutate(bookmark.id);
                        }}
                        disabled={bookmarkMap[bookmark.id]}
                      >
                      {bookmarkMap[bookmark.id] && <i class="fa-solid fa-bookmark" style={{
                        marginRight: "0.5rem"
                      }}></i>}
                        {bookmark.name}
                      </Button>
                    ))}
                    <Space.Compact
                      style={{ width: "100%", padding: "0 0.5rem" }}
                    >
                      <Input
                        size="small"
                        value={newBookmarkName}
                        onChange={(e) => {
                          setNewBookmarkName(e.target.value);
                        }}
                      />
                      <Button
                        onClick={() => {
                          createNewBookmark.mutate(newBookmarkName);
                        }}
                        size="small"
                        loading={createNewBookmark.isLoading}
                      >
                        Create New
                      </Button>
                    </Space.Compact>
                  </Space>
                </div>
              }
            >
              <Button className="option-button" type="link" size="small">
                <i class="fa-solid fa-book"></i>
                Bookmark
              </Button>
            </Popover>
          </Space>
          <br />
          {/* <Typography.Text>{selected?.abstract}</Typography.Text> */}
          {/* <InlineCommentHighlight abstract={selected?.abstract} /> */}
          <Tabs 
            activeKey={tab}
            onChange={(key) => {
              setTab(key);
            }}
          >
            <Tabs.TabPane tab="Summary" key="summary">
              <Space direction="vertical">
                <div className="summary-item">
                  <div>
                    <span className="label">Objective:</span>
                  </div>
                  <p>{selected.summary?.objective}</p>
                </div>
                <div className="summary-item">
                  <div>
                    <span className="label">Methods:</span>
                  </div>
                  <p>{selected.summary?.methods}</p>
                </div>
                <div className="summary-item">
                  <div>
                    <span className="label">Population:</span>
                  </div>
                  <p>{selected.summary?.population_characteristics}</p>
                </div>
                <div className="summary-item">
                  <div>
                    <span className="label">Endpoints:</span>
                  </div>
                  <p>{selected.summary?.endpoints_measured}</p>
                </div>
                <div className="summary-item">
                  <div>
                    <span className="label">Conclusion:</span>
                  </div>
                  <p>{selected.summary?.conclusion}</p>
                </div>
              </Space>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Abstract" key="abstract">
            <ReactMarkdown
              remarkPlugins={[remarkGfm]}
              children={selected.abstract}
            />
            </Tabs.TabPane>
            
            <Tabs.TabPane tab="Pipeline" key="1">
              {/* <DetailView
                itemId={selected?.id}
                pmid={selected?.pubmed_id}
                data={selected?.pipeline_outputs}
                feedback={feedback}
                onUpdateFeedback={handleAddFeedback}
                dataKey={"pipeline_outputs"}
              /> */}
              <PipelineDataView data={selected.pipeline_outputs} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="OpenAI" key="2">
              {/* <DetailView
                itemId={selected?.id}
                pmid={selected?.pubmed_id}
                data={selected?.gpt_data}
                feedback={feedback}
                onUpdateFeedback={handleAddFeedback}
                dataKey={"gpt_data"}
              /> */}
              <OpenAIDataView data={selected.gpt_data} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Validated Data" key="validation">
              <ValidationView
                key={selected?.id}
                itemId={selected?.id}
                pmid={selected?.pubmed_id}
                data={selected?.gpt_data}
                feedback={feedback}
                onUpdateFeedback={handleAddFeedback}
                dataKey={"gpt_data"}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Comments" key="3">
              <CommentSection
                docId={selected?.id}
                pubmedId={selected?.pubmed_id}
                key={selected?.id}
              />
            </Tabs.TabPane>
          </Tabs>
        </>
      ) : null}
    </>
  );
};

// export {UploadPDF}

export default PubDetailViewV2;
