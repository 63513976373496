import Typography from "antd/es/typography/Typography";
import React, { useState } from "react";
import { Button, Card, Modal, Row, Space, Upload, message } from "antd";
// import { useAuth } from "../../authContext";
import { PlusOutlined } from "@ant-design/icons";
import { PubmedDataItems } from "../../services/dataService";
// import { useOpenAI } from "../../utils";

const UploadPDF = ({ itemId,visible, onCancel, onConfirm }) => {
  const [state, setState] = useState({
    file: null,
    error: "",
  });

  const handleFileChange = (e) => {
    const file = e.file;

    if (!file) {
      setState((prev) => ({ ...prev, error: "Please select a file" }));
    } else {
      setState((prev) => ({ ...prev, file, error: "" }));
      handleConfirm(file);
    }
  };

  const handleConfirm = async (file) => {
    let formData = new FormData();
    formData.append("file", file);
    formData.append("annotations", "[]");
    await PubmedDataItems.uploadPdf(itemId, formData);
    message.success("PDF uploaded successfully");
    setState((prev) => ({ ...prev, file: null }));
    onConfirm();
  };

  

  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      title="Upload PDF"
      footer={null}
    >
      <Typography.Text>
        Import the PDF
      </Typography.Text>

      <Upload.Dragger
        onChange={handleFileChange}
        accept="application/pdf,.docx,.doc"
        disabled={state.file}
        beforeUpload={(file) => {
          // setState((prev) => ({ ...prev, file, error: "" }));
          return false;
        }}
        action={null}
      >
        <p className="ant-upload-drag-icon">
          <PlusOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
      </Upload.Dragger>
      {
        <Typography.Text type="danger" style={{ marginTop: "20px" }}>
          {state.error}
        </Typography.Text>
      }

      
      
    </Modal>
  );
};

export default UploadPDF;