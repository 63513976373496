// Generated automatically by nearley, version 2.20.1
// http://github.com/Hardmath123/nearley
(function () {
function id(x) { return x[0]; }
var grammar = {
    Lexer: undefined,
    ParserRules: [
    {"name": "search$ebnf$1$subexpression$1", "symbols": ["group"]},
    {"name": "search$ebnf$1", "symbols": ["search$ebnf$1$subexpression$1"]},
    {"name": "search$ebnf$1$subexpression$2", "symbols": ["group"]},
    {"name": "search$ebnf$1", "symbols": ["search$ebnf$1", "search$ebnf$1$subexpression$2"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "search", "symbols": ["search$ebnf$1"], "postprocess": ([terms]) => terms},
    {"name": "group$ebnf$1", "symbols": []},
    {"name": "group$ebnf$1$subexpression$1", "symbols": ["_", "operator", "__", "term", "_"]},
    {"name": "group$ebnf$1", "symbols": ["group$ebnf$1", "group$ebnf$1$subexpression$1"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "group", "symbols": ["term", "_", "group$ebnf$1"], "postprocess": ([first, , ...rest]) => (rest || []).reduce((acc, [,op, , term, ]) => ({ op, left: acc, right: term }), first)},
    {"name": "term", "symbols": ["quoted_term"]},
    {"name": "term", "symbols": ["keyword"], "postprocess": ([ term]) => term},
    {"name": "quoted_term$ebnf$1", "symbols": [/[^"]/]},
    {"name": "quoted_term$ebnf$1", "symbols": ["quoted_term$ebnf$1", /[^"]/], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "quoted_term", "symbols": [{"literal":"\""}, "quoted_term$ebnf$1", {"literal":"\""}], "postprocess": ([, str,]) => ({ type: "quoted", value: str.join("") })},
    {"name": "keyword$ebnf$1", "symbols": [/[a-zA-Z0-9]/]},
    {"name": "keyword$ebnf$1", "symbols": ["keyword$ebnf$1", /[a-zA-Z0-9]/], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "keyword", "symbols": ["__", "keyword$ebnf$1", "__"], "postprocess": (chars) => ({ type: "keyword", value: chars[1].join("") })},
    {"name": "operator$subexpression$1$string$1", "symbols": [{"literal":"A"}, {"literal":"N"}, {"literal":"D"}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "operator$subexpression$1", "symbols": ["operator$subexpression$1$string$1"]},
    {"name": "operator$subexpression$1$string$2", "symbols": [{"literal":"O"}, {"literal":"R"}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "operator$subexpression$1", "symbols": ["operator$subexpression$1$string$2"]},
    {"name": "operator$subexpression$1$string$3", "symbols": [{"literal":"N"}, {"literal":"O"}, {"literal":"T"}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "operator$subexpression$1", "symbols": ["operator$subexpression$1$string$3"]},
    {"name": "operator", "symbols": ["_", "operator$subexpression$1", "_"], "postprocess": ([, op,]) => op},
    {"name": "_$ebnf$1", "symbols": []},
    {"name": "_$ebnf$1", "symbols": ["_$ebnf$1", /[ \t\n\r]/], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "_", "symbols": ["_$ebnf$1"], "postprocess": () => null},
    {"name": "__$ebnf$1", "symbols": [/[ \t\n\r]/]},
    {"name": "__$ebnf$1", "symbols": ["__$ebnf$1", /[ \t\n\r]/], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "__", "symbols": ["__$ebnf$1"], "postprocess": () => null}
]
  , ParserStart: "search"
}
if (typeof module !== 'undefined'&& typeof module.exports !== 'undefined') {
   module.exports = grammar;
} else {
   window.grammar = grammar;
}
})();
